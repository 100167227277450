import React, { ChangeEvent, MouseEvent, useEffect } from 'react';
import {
  Boat,
  BoatsContainer,
  BoatsLoaderContainer,
  BoatsScrollContainer,
  BoatsScrollContent,
  MapContainer,
  PageContainer
} from './styles';
import useApi from '@hooks/useApi';
import { list, Heartbeat } from '@services/heartbeat';
import { useSearchParams } from 'react-router-dom';
import { IDS } from '@constants/queryStringParams';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox, TextField, Typography } from '@mui/material';
import { Scrollbar } from 'react-scrollbars-custom';
import { isOnline } from '@services/wcu';
import Online from '@components/Online';

const mapStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 32.164394144975695,
  lng: 34.79727973810219
};

function hasCoordinates(heartbeat: Heartbeat): boolean {
  return heartbeat?.latitude !== undefined && heartbeat?.longitude !== undefined;
}

function getCoordinatesPresentation(heartbeat: Heartbeat): string {
  if (!hasCoordinates(heartbeat)) {
    return 'No coordinates';
  }
  return `${heartbeat.latitude}, ${heartbeat.longitude}`;
}

const SeaMap: React.FC = () => {
  const getHeartbeats = useApi(list);
  const [searchParams] = useSearchParams();
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [selected, setSelected] = React.useState<Heartbeat | null>(null);
  const [unchecked, setUnchecked] = React.useState<number[]>([]);
  const [filter, setFilter] = React.useState<string>('');
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBKlUEbRJuuu-G-wQRZgGEoQeBLqw5W4Sk'
  });

  const showOnMap = (heartbeat: Heartbeat): void => {
    if (!hasCoordinates(heartbeat)) {
      return;
    }
    map?.setCenter({ lat: heartbeat.latitude, lng: heartbeat.longitude });
  };

  useEffect(() => {
    const ids = searchParams
      .get(IDS)
      ?.split('.')
      .map((id) => parseInt(id));
    if (ids) {
      void getHeartbeats.request({ ids });
    }
  }, []);

  useEffect(() => {
    if (!map || !getHeartbeats.data?.length) {
      return;
    }
    setSelected(getHeartbeats.data[0]);
  }, [getHeartbeats.data, map]);

  useEffect(() => {
    if (!selected || !map) {
      return;
    }
    showOnMap(selected);
  }, [selected, map]);

  const onLoad = React.useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  const toggleSelected = (heartbeat: Heartbeat): void => {
    setSelected((state) => (state?.wcu_id === heartbeat.wcu_id ? null : heartbeat));
  };

  const handleCheck = (isChecked: boolean, heartbeat: Heartbeat): void => {
    if (isChecked) {
      return setUnchecked(unchecked.filter((c) => c !== heartbeat.wcu_id));
    }
    setUnchecked(unchecked.concat([heartbeat.wcu_id]));
  };

  const isChecked = (heartbeat: Heartbeat): boolean =>
    unchecked.find((c) => c === heartbeat.wcu_id) === undefined;

  return (
    <PageContainer>
      <BoatsContainer>
        <TextField
          size="medium"
          label="Boat name"
          sx={{ width: 'calc(100% - 28px)', marginLeft: '10px' }}
          value={filter}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setFilter(event.target.value);
          }}></TextField>
        <BoatsScrollContainer>
          <Scrollbar>
            <BoatsScrollContent>
              {getHeartbeats.data?.map((heartbeat) => {
                const header = `${heartbeat.wcu_id} - ${heartbeat.wcu_info.boat_name}`;
                if (filter && !header.toLowerCase().includes(filter)) {
                  return null;
                }
                return (
                  <Boat
                    key={heartbeat.wcu_id}
                    $selected={selected?.wcu_id === heartbeat.wcu_id}
                    onClick={() => {
                      toggleSelected(heartbeat);
                    }}>
                    <Typography
                      variant="h6"
                      sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      <Online isOnline={isOnline(heartbeat.wcu_info)} />
                      &nbsp;
                      {header}
                    </Typography>
                    <Typography
                      sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      {heartbeat.country ?? 'No Country'} - {getCoordinatesPresentation(heartbeat)}
                    </Typography>
                    <Checkbox
                      checked={isChecked(heartbeat)}
                      onClick={(event: MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleCheck(event.target.checked, heartbeat);
                      }}
                      sx={{
                        position: 'absolute',
                        right: '0',
                        top: '50%',
                        transform: 'translateY(-50%)'
                      }}
                    />
                  </Boat>
                );
              })}
            </BoatsScrollContent>
          </Scrollbar>
        </BoatsScrollContainer>
        {getHeartbeats.loading && (
          <BoatsLoaderContainer>
            <CircularProgress />
          </BoatsLoaderContainer>
        )}
      </BoatsContainer>
      <MapContainer>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={mapStyle}
            zoom={10}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}>
            {getHeartbeats.data?.map((heartbeat) =>
              hasCoordinates(heartbeat) && isChecked(heartbeat) ? (
                <Marker
                  key={heartbeat.wcu_id}
                  position={{ lat: heartbeat.latitude, lng: heartbeat.longitude }}
                />
              ) : null
            )}
          </GoogleMap>
        )}
      </MapContainer>
    </PageContainer>
  );
};

export default SeaMap;
