import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { WCU } from '@services/wcu';
import { list, MapData } from '@services/mapData';
import { RUNNING } from '@constants/mapTaskStatus';
import {
  ActionsContainer,
  BusyBoats,
  BusyContainer,
  ContentContainer,
  FlagsContainer,
  LoaderContainer,
  MainMapsContainer,
  MapContainer,
  MapsContainer,
  MoveBackIcon,
  MoveIcon,
  RegionContainer,
  RegionInnerContainer,
  RegionName
} from './styles';
import useApi from '@hooks/useApi';
import CircularProgress from '@mui/material/CircularProgress';
import PaperComponent from '@components/Paper';
import { Switch, Typography } from '@mui/material';
import { Scrollbar } from 'react-scrollbars-custom';
import { checkTasks, create } from '@services/platformMaps';
import AlertDialog from '@components/AlertDialog';

interface AssociatePropsDialogProps {
  open: boolean;
  onCloseRequest: () => void;
  wcu: WCU[];
}

const TASKS_CHECK_TIMEOUT = 10000;

function mapsToDict(maps: MapData[]): { [region: string]: MapData[] } {
  const dict: { [region: string]: MapData[] } = {};
  const copy = [...maps];
  copy.sort((a, b) => {
    const res = a.region.toLowerCase().localeCompare(b.region.toLowerCase());
    if (res === 0) {
      return a.country.toLowerCase().localeCompare(b.country.toLowerCase());
    }
    return res;
  });
  for (const map of copy) {
    if (!dict[map.region]) {
      dict[map.region] = [];
    }
    dict[map.region].push(map);
  }
  return dict;
}

function moveMaps(
  maps: MapData[],
  source: MapData[],
  target: MapData[]
): {
  source: MapData[];
  target: MapData[];
} {
  const sourceCopy: MapData[] = [...source];
  const targetCopy: MapData[] = [...target];
  for (const map of maps) {
    targetCopy.push(map);
    const idx = sourceCopy.findIndex((m) => m.country === map.country);
    if (idx !== -1) {
      sourceCopy.splice(idx, 1);
    }
  }
  return {
    source: sourceCopy,
    target: targetCopy
  };
}

export default function UpdateVersionsDialog(props: AssociatePropsDialogProps): ReactElement {
  const { open, onCloseRequest, wcu } = props;
  const [isForced, setIsForced] = useState<boolean>(false);
  const [availableMaps, setAvailableMaps] = useState<MapData[]>([]);
  const [associateMaps, setAssociateMaps] = useState<MapData[]>([]);
  const [busyWCUs, setBusyWCUs] = useState<WCU[] | null>(null);
  const maps = useApi(list);
  const runningTasks = useApi(checkTasks);
  const createTasks = useApi(create);
  const tasksTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const showResultsRef = useRef<boolean>(false);
  const [message, setMessage] = useState<string | ReactElement>('');

  const setDefaults = (): void => {
    setIsForced(false);
    setBusyWCUs(null);
    setAssociateMaps([]);
    setAvailableMaps(maps.data ? [...maps.data] : []);
  };

  const wcuMap = useMemo(() => {
    const map: { [id: number]: WCU } = {};
    if (wcu) {
      for (const unit of wcu) {
        map[unit.wcu_id] = unit;
      }
    }
    return map;
  }, [wcu]);

  useEffect(() => {
    return () => {
      clearTimeout(tasksTimeoutRef.current);
    };
  }, []);

  const checkTasksStatus = (): void => {
    void runningTasks.request(wcu.map((u) => u.wcu_id));
  };

  const initCheckTasksStatusInterval = (): void => {
    clearTimeout(tasksTimeoutRef.current);
    checkTasksStatus();
    tasksTimeoutRef.current = setInterval(checkTasksStatus, TASKS_CHECK_TIMEOUT);
  };

  useEffect(() => {
    if (!open) {
      clearTimeout(tasksTimeoutRef.current);
      return setDefaults();
    }
    initCheckTasksStatusInterval();
    void maps.request();
  }, [open]);

  useEffect(() => {
    if (runningTasks.data === null || !wcu) {
      return;
    }
    const busy = [];
    for (const id in runningTasks.data) {
      const parsedId = parseInt(id);
      if (
        (runningTasks.data[id] === RUNNING || createTasks.loading) &&
        wcuMap[parsedId] !== undefined
      ) {
        busy.push(wcuMap[parsedId]);
      }
    }
    busy.sort((a, b) => a.wcu_id - b.wcu_id);
    setBusyWCUs(busy);
    if (busy.length === 0 && showResultsRef.current) {
      showResultsRef.current = false;
      const msg = <Fragment>{getWCUsStatus(wcu)}</Fragment>;
      setMessage(msg);
    }
  }, [runningTasks.data, createTasks.loading]);

  useEffect(() => {
    if (!maps.data) {
      return;
    }
    setAvailableMaps(maps.data);
    setAssociateMaps([]);
  }, [maps.data]);

  useEffect(() => {
    if (!createTasks.error) {
      return;
    }
    const msg = <Typography>{createTasks.error}</Typography>;
    setMessage(msg);
  }, [createTasks.error]);

  useEffect(() => {
    if (!maps.error) {
      return;
    }
    const msg = <Typography>{maps.error}</Typography>;
    setMessage(msg);
  }, [maps.error]);

  const availableMapsElements = useMemo(() => {
    const res = [];
    const dict = mapsToDict(availableMaps);
    for (const region in dict) {
      const regionElement = (
        <RegionContainer key={region}>
          <RegionName>
            <Typography variant="h6">{region}</Typography>
            <MoveIcon
              style={{ justifySelf: 'end' }}
              onClick={() => {
                const res = moveMaps(dict[region], availableMaps, associateMaps);
                setAssociateMaps(res.target);
                setAvailableMaps(res.source);
              }}
            />
          </RegionName>
          <RegionInnerContainer>
            {dict[region].map((m) => (
              <MapContainer key={m.map_id}>
                <Typography>
                  {m.country} - {m.vendor}
                </Typography>
                <MoveIcon
                  onClick={() => {
                    const res = moveMaps([m], availableMaps, associateMaps);
                    setAssociateMaps(res.target);
                    setAvailableMaps(res.source);
                  }}
                />
              </MapContainer>
            ))}
          </RegionInnerContainer>
        </RegionContainer>
      );
      res.push(regionElement);
    }
    return res;
  }, [availableMaps]);

  const associateMapsElements = useMemo(() => {
    const res = [];
    const dict = mapsToDict(associateMaps);
    for (const region in dict) {
      const regionElement = (
        <RegionContainer key={region}>
          <RegionName>
            <Typography variant="h6">{region}</Typography>
            <MoveBackIcon
              onClick={() => {
                const res = moveMaps(dict[region], associateMaps, availableMaps);
                setAssociateMaps(res.source);
                setAvailableMaps(res.target);
              }}
            />
          </RegionName>
          <RegionInnerContainer>
            {dict[region].map((m) => (
              <MapContainer key={m.map_id}>
                <Typography>
                  {m.country} - {m.vendor}
                </Typography>
                <MoveBackIcon
                  onClick={() => {
                    const res = moveMaps([m], associateMaps, availableMaps);
                    setAssociateMaps(res.source);
                    setAvailableMaps(res.target);
                  }}
                />
              </MapContainer>
            ))}
          </RegionInnerContainer>
        </RegionContainer>
      );
      res.push(regionElement);
    }
    return res;
  }, [associateMaps]);

  const getWCUsStatus = (wcus: WCU[]): ReactElement[] => {
    return wcus.map((u) => {
      const message = `${u.wcu_id}(${u.boat_name || 'no name'})`;
      const status = runningTasks?.data?.[String(u.wcu_id)];
      return (
        <Typography key={u.wcu_id}>
          {message}
          {status ? ' - ' + status : ''}
        </Typography>
      );
    });
  };

  const onApply = (): void => {
    const wcuIds = wcu.map((u) => u.wcu_id);
    const mapIds = associateMaps.map((m) => m.map_id);
    void createTasks.request(wcuIds, mapIds, isForced).then(async () => {
      showResultsRef.current = true;
      initCheckTasksStatusInterval();
    });
    initCheckTasksStatusInterval();
  };

  const loadingIsActive = maps.loading;
  const showBusy = busyWCUs !== null && busyWCUs.length > 0;
  const applyEnabled =
    busyWCUs !== null &&
    !showBusy &&
    !loadingIsActive &&
    associateMaps.length > 0 &&
    !createTasks.loading;

  return (
    <Dialog
      maxWidth="md"
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title">
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Associate Maps
      </DialogTitle>
      <DialogContent>
        <ContentContainer>
          {loadingIsActive && (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          )}
          <MainMapsContainer>
            <MapsContainer>
              <Scrollbar>{availableMapsElements}</Scrollbar>
            </MapsContainer>
            <MapsContainer>
              <Scrollbar>{associateMapsElements}</Scrollbar>
            </MapsContainer>
          </MainMapsContainer>
          <FlagsContainer>
            <Typography>Immediate Update:</Typography>
            <Switch
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setIsForced(event.target.checked);
              }}
              value={isForced}
            />
          </FlagsContainer>
          {showBusy && (
            <BusyContainer>
              <CircularProgress />
              <Typography variant="h6">WCU map association task in progress</Typography>
              <BusyBoats>
                <Scrollbar>{getWCUsStatus(busyWCUs)}</Scrollbar>
              </BusyBoats>
            </BusyContainer>
          )}
        </ContentContainer>
        <AlertDialog
          title="Associate Result"
          content={message}
          open={message !== ''}
          onCloseRequest={() => {
            setMessage('');
          }}
        />
      </DialogContent>
      <ActionsContainer>
        <Button sx={{ float: 'left' }} onClick={onCloseRequest}>
          Close
        </Button>
        <Button
          disabled={!applyEnabled}
          sx={{ float: 'right' }}
          variant="contained"
          onClick={onApply}>
          Apply
        </Button>
      </ActionsContainer>
    </Dialog>
  );
}
